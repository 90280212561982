import Macy from 'macy';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';


/**
 * Toggle the navigation menu.
 * @param e
 */
const toggleNavigation = (e) => {
    e.preventDefault();
    navigation.classList.toggle('navigation--open');
}

/**
 * Close the navigation menu.
 */
const closeNavigation = () => {
    navigation.classList.remove('navigation--open');
}

/**
 * Close the navigation menu if the user clicks outside of it.
 * @param e
 */
const clickOutside = (e) => {
    if (e.target.classList.contains('navigation')) {
        e.preventDefault();
        closeNavigation();
    }
}

/**
 * Handle navigation item link click.
 * Scroll to the target element if the link is an anchor.
 * @param e
 */
const handleNavigationClick = (e) => {
    console.log('Click');
    const href = e.target.getAttribute('href');
    if (href.slice(0,1) === '#') {
        e.preventDefault();
        closeNavigation();
        const target = document.querySelector(href);
        target.scrollIntoView({
            behavior: 'smooth'
        });
    }
}

const navigation = document.querySelector('.navigation');
navigation.addEventListener('click', clickOutside);

const navigation_toggle = document.querySelectorAll('.navigation__toggle');
[...navigation_toggle].forEach(toggle => toggle.addEventListener('click', toggleNavigation));

const navigation_links = document.querySelectorAll('.navigation__item--scroll a');
[...navigation_links].forEach(link => link.addEventListener('click', handleNavigationClick));

// Awards with Masonry & Lightbox
const awards = document.querySelectorAll('.portfolio__awards');
if (awards.length > 0) {
    [...awards].forEach(container => Macy({
        columns: 1,
        margin: 24,
        container: container,
        waitForImages: true,
        mobileFirst: true,
        breakAt: {
            768: {
                columns: 2,
                margin: 28
            },
            1024: {
                margin: 32,
            },
        },
    }));

    const gallery = new PhotoSwipeLightbox({
        gallery: '.portfolio__awards',
        children: '.portfolio__award',
        pswpModule: () => import('photoswipe'),
        showHideAnimationType: 'fade',
        options: {
            bgOpacity: 0.9,
            showHideOpacity: true,
            history: false,
        },
    });

    gallery.init();
}
